import CV from "../Components/CV";
import Info from "../Components/Info";
const CVpage = () => {

    return (
        <div style={{ marginTop: '150px' }}>
            <Info />
            <CV />
        </div>
    )
};

export default CVpage;
